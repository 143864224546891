import React from "react";

interface TextFieldPropsType{
    placeholder: String
    label: String
    customId: string
    register: any
    errors: String | undefined,
    type?: String
    readOnly?: boolean
}

const TextField:React.FC<TextFieldPropsType> = ({ placeholder, label, customId, register, errors, readOnly = false, ...restProps }) => {
    return (
        <div style={{marginBottom: 14}}>
            <label htmlFor={customId} className="text-label" style={{display: 'block', color: '#1346a3',fontWeight:"bold"}}>
                {label}
            </label>
            <input placeholder={placeholder} readOnly={readOnly} id={customId} {...restProps} {...register(customId)} className="text-field" />
            {errors && <p style={{color: 'red'}}>{errors}</p>}
        </div>
    )
}
export default TextField;