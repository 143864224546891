import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import GetSubjects from '../graphql/queries/GetSubjects'
import CreateSubject from '../components/forms/CreateSubject'

const Subject:React.FC = () => {
  const [show, setShow] = useState(false)
  const [order, setOrder] = useState<String>("name");
  const [dir, setDir] = useState<Direction>('asc');

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetSubjects, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "name",
      label: "Name",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const rows =
    (data?.subjects || []).map((row: any) => {
      const action = (
        <TableActionCell
          hideEdit={false}
          editLabel="Edit Subject"
          onEdit={() => {}}
          hideDestroy={true}
          tableRow={{ rowId: row.id }}
          onDestroy={() => {}}
        />
      );

      return {
        ...row,
        action,
      };
    });

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        show={show} 
        setShow={setShow} 
        setSearch={() => {}} 
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Subjects"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal Component={<CreateSubject handleClose={() => setShow(false)} />} handleClose={() => setShow(false)} show={show} />
    </div>
  )
}

export default Subject