import { gql } from "@apollo/client";

export default gql`
query GetQuizzes($where: JSON){
  quizzes(where: $where) {
    id
    description
    isActive
    title
    totalQuestions
    category {
      id
      name
    }
    subCategory {
      id
      name
    }
    _count {
      questions
    }
  }
}
`