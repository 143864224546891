import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation } from '@apollo/client';
import Subject from '../../graphql/mutations/Subject';

interface SubjectCreateType {
    name: string;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        name: yup.string().required("Category name is required!"),
    })
    .required()

const CreateSubject: React.FC<Props> = ({handleClose}) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createSubject] = useMutation(Subject)

    const onFormSubmit = (v: SubjectCreateType) => {
        createSubject({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetSubjects']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Subject
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Subject"
                    placeholder="Enter subject name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateSubject