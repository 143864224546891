import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation } from '@apollo/client';
import Question from '../../graphql/mutations/Question';
import ProgressBar from '../common/ProgressBar';
import type { OptionType } from '../../service/types';

interface QuestionCreateType {
    question: string
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correct: string;
}

interface Props{
    handleClose: () => void;
    quizId?: number;
    total?: number;
    questions?: number
}

const schema = yup
    .object({
        question: yup.string().required("Ttile is required!"),
        optionA: yup.string().required("Option A is required!"),
        optionB: yup.string().required("Option B is required!"),
        optionC: yup.string().required("Option C is required!"),
        optionD: yup.string().required("Option D is required!"),
        correct: yup.string().required("Correct answer is required")
    })
    .required()

const formatOption = (v: QuestionCreateType) => {
    const optionA = v.optionA.trim()
    const optionB = v.optionB.trim()
    const optionC = v.optionC.trim()
    const optionD = v.optionD.trim()
    const correct = v.correct.trim()
    if(correct === ''){
        return false
    }
    const validAns = ['A', 'a', optionA, 'B', 'b', optionB, 'C', 'c', optionC, 'D', 'd', optionD]
    if(!validAns.includes(correct)){
        return false
    }
    const returnData : OptionType[] = [
        {
            label: optionA,
            isCorrect:  (correct === 'A' || correct === 'a' || correct === optionA)
        },
        {
            label: optionB,
            isCorrect:  (correct === 'B' || correct === 'b' || correct === optionB)
        },
        {
            label: optionC,
            isCorrect:  (correct === 'C' || correct === 'c' || correct === optionC)
        },
        {
            label: optionD,
            isCorrect:  (correct === 'D' || correct === 'd' || correct === optionD)
        },
    ]
    return returnData
}

const CreateQuestions: React.FC<Props> = ({handleClose, quizId, total, questions}) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [progress, setProgress] = useState(questions ? questions + 1 : 1)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createQuestion] = useMutation(Question)

    const onFormSubmit = (v: QuestionCreateType) => {
        const options = formatOption(v)
        if(!options){
            notification({message: 'Answer is required!', type: "danger"})
        }
        createQuestion({
            variables: {
                input: {
                    question: v.question,
                    options,
                    quizId
                }
            },
            onCompleted: (data) => {
                console.log('data', data);
                if(progress === total){
                    handleClose()
                }else{
                    reset()
                    setProgress(progress + 1)
                }
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetQuizzes', 'GetQuestions']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Questions
            </Typography>
            <ProgressBar progress={progress} total={total} />
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Question Title"
                    placeholder="Enter question title"
                    customId="question"
                    type="text"
                    register={register}
                    errors={errors?.question?.message}
                />
                <TextField
                    label="Option A"
                    placeholder="Enter option A"
                    customId="optionA"
                    type="text"
                    register={register}
                    errors={errors?.optionA?.message}
                />
                <TextField
                    label="Option B"
                    placeholder="Enter option B"
                    customId="optionB"
                    type="text"
                    register={register}
                    errors={errors?.optionB?.message}
                />
                <TextField
                    label="Option C"
                    placeholder="Enter option C"
                    customId="optionC"
                    type="text"
                    register={register}
                    errors={errors?.optionC?.message}
                />
                <TextField
                    label="Option D"
                    placeholder="Enter option D"
                    customId="optionD"
                    type="text"
                    register={register}
                    errors={errors?.optionD?.message}
                />
                <TextField
                    label="Correct Option"
                    placeholder="Enter correct option like A, B, C, D"
                    customId="correct"
                    type="text"
                    register={register}
                    errors={errors?.correct?.message}
                />
                <SubmitButton label={progress < (total||0) ? "Next" : "Submit"} />
            </form>
        </div>
    )
}

export default CreateQuestions