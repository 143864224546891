import { gql } from "@apollo/client";

export default gql`
query GetSubCategory($id: Int!){
  subCategory(id: $id) {
    id
    name
    categoryId
    description
  }
}
`