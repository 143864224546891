import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation } from '@apollo/client';
import SubCategory from '../../graphql/mutations/SubCategory';
import SelectCategory from '../select/CategorySelect';
import SubjectFilter from '../filters/SubjectFilter';
import Textarea from '../common/Textarea';

interface SubCategoryCreateType {
    name: string;
    categoryId: number;
    description: string;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        name: yup.string().required("Sub Category name is required!"),
        description: yup.string().required("Sub Category name is required!"),
        categoryId: yup.number().required("Category is required!")
    })
    .required()

const CreateSubCategory: React.FC<Props> = ({handleClose}) => {
    const [subjectId, setSubjectId] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createSubCategory] = useMutation(SubCategory)

    const onFormSubmit = (v: SubCategoryCreateType) => {
        createSubCategory({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetSubCategories']
        })
    }
    const handleSubjectSelect = useCallback((v:number) => {
        setSubjectId(v)
    },[])
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Sub Category
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />
                <SelectCategory
                    errors={errors}
                    label='Select Category'
                    name='categoryId'
                    register={register}
                    subjectId={subjectId}
                />
                <TextField
                    label="Sub Category"
                    placeholder="Enter sub category name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateSubCategory