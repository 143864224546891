import React, { useState, useCallback, useEffect } from 'react'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import AddButton from '../components/common/AddButton'
import CreateContent from '../components/forms/CreateContent'
import GetContents from '../graphql/queries/GetContents'
import SubCategoryFilter from '../components/filters/SubCategoryFilter'
import EditContent from '../components/forms/EditContent'

const Contents:React.FC = () => {
  const [show, setShow] = useState(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [editId, setEditId] = useState<number>()
  const [subCatId, setSubCatId] = useState<number>()
  const [order, setOrder] = useState<String>("title");
  const [dir, setDir] = useState<Direction>('asc');
  const [search, setSearch] = useState<string>('')

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetContents, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        search,
        subCategoryId: subCatId
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "title",
      label: "Title",
      width: 100,
    },
    {
      name: "content",
      label: "Description",
      align: "center",
    },
    {
      name: "fileUrl",
      label: "File",
      align: "center",
    },
    {
      name: "list",
      label: "List",
      align: "center",
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const handleEdit = (id: number) => {
    setEditId(id)
    setShowEdit(true)
  }
  
  const rows =
    (data?.contents || []).map((row: any) => {
      
      const action = (
        <TableActionCell
          hideEdit={false}
          editLabel="Edit question"
          onEdit={handleEdit}
          hideDestroy={true}
          tableRow={{ rowId: row.id }}
          onDestroy={() => {}}
        />
      );
      return {
        ...row,
        action,
      };
    });

    const handleSubCatChange = useCallback((v:number) => {
      setSubCatId(v)
    },[])

    const handleSearch = useCallback((v:string) => {
      setSearch(v)
    },[])

    const handleShow = useCallback((v:boolean) => {
      setShow((prev: boolean) => !prev)
    },[])

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        setSearch={handleSearch} 
        show={show}
        setShow={handleShow}
        filter={<SubCategoryFilter value={subCatId} setOptionValue={handleSubCatChange} />} 
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Questions"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal 
        Component={<EditContent id={editId}
        handleClose={() => setShowEdit(false)} />} 
        handleClose={() => setShowEdit(false)} 
        show={showEdit}
      />
      <AbstractModal 
        Component={<CreateContent handleClose={() => setShow(false)} />} 
        handleClose={() => setShow(false)} 
        show={show}
      />
    </div>
  )
}

export default Contents