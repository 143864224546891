import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface Props{
    progress: number;
    total?: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number,  }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} style={{height: 20, borderRadius: 10}} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: '#ccc' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ProgressBar: React.FC<Props> = ({ progress, total }) => {

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={(progress * 100)/(total||0)} />
    </Box>
  );
}

export default ProgressBar