import { gql } from "@apollo/client";

export default gql`
query GetQuestion($questionId: Int!){
  question(id: $questionId) {
    id
    question
    options {
      id
      isCorrect
      label
    }
  }
}
`