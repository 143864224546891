import { gql } from "@apollo/client";

export default gql`
query GetSubCategories($where: JSON){
  subCategories(where: $where) {
    id
    name
    description
  }
}
`