import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import GetQuzzes from '../graphql/queries/GetQuzzes'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import CreateQuiz from '../components/forms/CreateQuiz'
import { Add } from '@mui/icons-material'
import CreateQuestions from '../components/forms/CreateQuestions'
import { QuizType } from '../service/types'
import SubjectFilter from '../components/filters/SubjectFilter'
import CategoryFilter from '../components/filters/CategoryFilter'

const Quizzes:React.FC = () => {
  const [show, setShow] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [quiz, setQuiz] = useState<QuizType>()
  const [order, setOrder] = useState<String>("title");
  const [dir, setDir] = useState<Direction>('asc');
  const [search, setSearch] = useState<string>('')
  const [categoryId, setCategory] = useState<number>()
  const [subjectId, setSubjectId] = useState<number>()

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetQuzzes, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        search,
        categoryId,
        subjectId,
        orderBy: `${order} ${dir}`
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "title",
      label: "Title",
      width: 100,
    },
    {
      name: "description",
      label: "Description",
      align: "center",
    },
    {
      name: "totalQuestions",
      label: "# Questions",
      align: "center",
      width: 100,
    },
    {
      name: "isActive",
      label: "Active",
      align: "center",
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const handleCreateQuestion = (id: number) => {
    const quiz = data?.quizzes?.find((item:any) => item.id === id)
    setQuiz(quiz)
    setQuestionModal(true)
  }

  const rows =
    (data?.quizzes || []).map((row: any) => {
      
      const action = (
        <TableActionCell
          hideEdit={true}
          editLabel="Edit User"
          onEdit={() => {}}
          hideDestroy={false}
          tableRow={{ rowId: row.id }}
          onDestroy={() => {}}
          moreActions={row._count.questions < row.totalQuestions ? [
            {
              label: 'Add Question',
              action: handleCreateQuestion,
              icon: <span><Add /> Questions</span>
            }
          ]: undefined}
        />
      );

      return {
        ...row,
        action,
      };
    });

    const handleCategorySelect = useCallback((v:number) => {
      setCategory(v)
    },[])

    const handleSubjectSelect = useCallback((v:number) => {
      setSubjectId(v)
    },[])

    const handleSearch = useCallback((v: string) => {
      setSearch(v)
    }, [])
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        show={show} 
        setShow={setShow} 
        setSearch={handleSearch}
        filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
        filter2={<CategoryFilter setOptionValue={handleCategorySelect} value={categoryId} subjectId={subjectId}  />} 
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Quizzes"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal Component={<CreateQuiz handleClose={() => setShow(false)} />} handleClose={() => setShow(false)} show={show} />
      <AbstractModal Component={<CreateQuestions quizId={quiz?.id} total={quiz?.totalQuestions} questions={quiz?.questions.length} handleClose={() => setQuestionModal(false)} />} handleClose={() => setQuestionModal(false)} show={questionModal} />
    </div>
  )
}

export default Quizzes