import { gql } from "@apollo/client";

export default gql`
query GetContents($where: JSON){
  contents(where: $where) {
    id
    content
    title
    fileUrl
    list
    category {
      id
      name
    }
    subCategory {
      id
      name
    }
  }
}
`