import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation, useQuery } from '@apollo/client';
import GetQuestion from '../../graphql/queries/GetQuestion';
import UpdateOption from '../../graphql/mutations/UpdateOption';
import Textarea from '../common/Textarea';

interface QuestionCreateType {
    question: string
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correct: string;
}

interface Props{
    handleClose: () => void;
    questionId?: number;
}

const schema = yup
    .object({
        question: yup.string().required("Ttile is required!"),
        optionA: yup.string().required("Option A is required!"),
        optionB: yup.string().required("Option B is required!"),
        optionC: yup.string().required("Option C is required!"),
        optionD: yup.string().required("Option D is required!"),
        correct: yup.string().required("Correct answer is required")
    })
    .required()

const formatOption = (v: QuestionCreateType) => {
    const optionA = v.optionA.trim()
    const optionB = v.optionB.trim()
    const optionC = v.optionC.trim()
    const optionD = v.optionD.trim()
    const correct = v.correct.trim()
    if(correct === ''){
        return false
    }
    const validAns = ['A', 'a', optionA, 'B', 'b', optionB, 'C', 'c', optionC, 'D', 'd', optionD]
    if(!validAns.includes(correct)){
        return false
    }
    let correctOption;
    if(correct === 'A' || correct === 'a' || correct === optionA){
        correctOption = optionA
    }else if(correct === 'B' || correct === 'b' || correct === optionB){
        correctOption = optionB
    }else if(correct === 'C' || correct === 'c' || correct === optionC){
        correctOption = optionC
    }else{
        correctOption = optionD
    }
    return correctOption
}

const EditQuestion: React.FC<Props> = ({handleClose, questionId}) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const {data, error, loading} = useQuery(GetQuestion, {
        fetchPolicy: "network-only",
        variables: {
            questionId
        }
    })

    useEffect(() => {
        if(!loading && data && data.question){
            console.log('data', data);
            const {question, options} = data.question;
            const corectOption = options.find((item:any) => !!item.isCorrect)
            console.log('corectOption', corectOption);
            
            setValue('question', question)
            setValue('correct', corectOption?.label)
            setValue('optionA', options[0].label)
            setValue('optionB', options[1].label)
            setValue('optionC', options[2].label)
            setValue('optionD', options[3].label)
        }
        if(error){
            notification({message: error.message, type: "danger"})
        }
    }, [loading, error, data, setValue])
    
    const [updateOption] = useMutation(UpdateOption)

    const onFormSubmit = (v: QuestionCreateType) => {
        const option = formatOption(v)
        if(!option){
            notification({message: 'Answer is required!', type: "danger"})
        }
        const optionId = data?.question?.options.find((item: any) => item.label === option)
        console.log('option', optionId, option);
        
        updateOption({
            variables: {
                input: {
                    questionId,
                    id: optionId.id,
                    isCorrect: true
                }
            },
            onCompleted: (data) => {
                if(data){
                    handleClose()
                }
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetQuestions']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Question
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <Textarea
                    label="Question Title"
                    placeholder="Enter question title"
                    customId="question"
                    readOnly
                    register={register}
                    numRows={2}
                    errors={errors?.question?.message}
                />
                <TextField
                    label="Option A"
                    placeholder="Enter option A"
                    customId="optionA"
                    readOnly
                    type="text"
                    register={register}
                    errors={errors?.optionA?.message}
                />
                <TextField
                    label="Option B"
                    placeholder="Enter option B"
                    customId="optionB"
                    readOnly
                    type="text"
                    register={register}
                    errors={errors?.optionB?.message}
                />
                <TextField
                    label="Option C"
                    placeholder="Enter option C"
                    customId="optionC"
                    type="text"
                    readOnly
                    register={register}
                    errors={errors?.optionC?.message}
                />
                <TextField
                    label="Option D"
                    placeholder="Enter option D"
                    customId="optionD"
                    readOnly
                    type="text"
                    register={register}
                    errors={errors?.optionD?.message}
                />
                <TextField
                    label="Correct Option"
                    placeholder="Enter correct option like A, B, C, D"
                    customId="correct"
                    type="text"
                    register={register}
                    errors={errors?.correct?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditQuestion