import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useMutation, useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import GetCategories from '../graphql/queries/GetCategories'
import CreateCategory from '../components/forms/CreateCategory'
import SubjectFilter from '../components/filters/SubjectFilter'
import EditCategory from '../components/forms/EditCategory'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers/store'
import { confirmAlert } from 'react-confirm-alert'
import CustomAlert from '../components/common/CustomAlert'
import DeleteCategory from '../graphql/mutations/DeleteCategory'

const Category:React.FC = () => {
  const [show, setShow] = useState(false)
  const [order, setOrder] = useState<String>("name");
  const [dir, setDir] = useState<Direction>('asc');
  const [subjectId, setSubjectId] = useState<number>()
  const [editId, setEditId] = useState<number>()
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const {theme} = useSelector((state: RootState) => state.sidebar)

  const [removeCategory] = useMutation(DeleteCategory)

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetCategories, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        subjectId: subjectId === 0 ? undefined : subjectId
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "name",
      label: "Name",
      width: 100,
    },
    {
      name: "description",
      label: "Description",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const handleEdit = useCallback((id:number) => {
    setEditId(id)
    setShowEdit(true)
  },[])

  const handleDelete = (id: number) => {
    removeCategory({
      variables: {
        id
      },
      onCompleted: (data) => {
        if(data){
          notification({message: "Deleted successfully!"})
        }
      },
      onError: (err) => {
        notification({message: err.message, type: "danger"})
      },
      refetchQueries: ['GetCategories']
    })
  }

  const onDelete = (id: number) => {
    confirmAlert({
      customUI: ({onClose}) =>
        <CustomAlert
          id={id}
          onClose={onClose}
          message="Are you sure you want to delete category?"
          callback={handleDelete}
          theme={theme}
        />
    })
  }

  const rows =
    (data?.categories || []).map((row: any) => {
      const action = (
        <TableActionCell
          hideEdit={false}
          editLabel="Edit User"
          onEdit={handleEdit}
          hideDestroy={false}
          tableRow={{ rowId: row.id }}
          onDestroy={onDelete}
        />
      );

      return {
        ...row,
        action,
      };
    });

    const handleFilter = useCallback((v: number) => {
      setSubjectId(v)
    }, [])

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        show={show} 
        setShow={setShow} 
        setSearch={() => {}} 
        filter={<SubjectFilter setOptionValue={handleFilter} value={subjectId} />}
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Categories"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal 
        Component={<CreateCategory handleClose={() => setShow(false)} />} 
        handleClose={() => setShow(false)} 
        show={show} 
      />
      <AbstractModal 
        Component={<EditCategory handleClose={() => setShowEdit(false)} id={editId || 0} />} 
        handleClose={() => setShowEdit(false)} 
        show={showEdit} 
      />
    </div>
  )
}

export default Category